















import { Component, Vue } from 'vue-property-decorator'

import CenteredMedium from '@/layouts/Panels/CenteredMedium66.vue'

@Component({
  components: {
    CenteredMedium
  }
})
export default class MedicalFormsLayout extends Vue {

}
